// ErrorDialog.js

import React from 'react';
import './errormodal.css'; // Import CSS for styling

const ErrorModal = ({ message, isOpen, onClose }) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div className="error-modal">
            <div className="error-modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default ErrorModal;
