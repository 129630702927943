import React from "react";

const BulletText = ({ data }) => {
  const { values } = data;
  // console.log("BAL", values)
  return (
    <div className="control_padding">
      <div>
        {values &&
          values.length > 0 &&
          values?.map((item, ind) => (
            <p key={ind}
              dangerouslySetInnerHTML={{
                __html: item?.bodyText || "body text",
              }}
              className="editor-inner-text"
              style={{
                color: item?.bodyTextColor,
                fontSize: item?.fontSize || '14px'
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default BulletText;
