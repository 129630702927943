import React,{useEffect} from 'react';
import './../index.css'
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getMammoFacLocationAction
} from "../../redux/store/mam/mammoAction";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
import { decodeToken } from "../../utils";
// Define the Mamo component
const Mamo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { company, campaignId } = useParams();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    dispatch(setError(""));
    const token = queryParams.get("token");
    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
    }
  }, []);

  return (
    <div className='container-fluid g-0'>
      <div className='card card-custom card-help'>
        <div className='card-header bg-inky text-white text-center'>
          <img className=" w-15" src="../../../Logo.svg" />
        </div>
        <div className='card-body card-body-h-vh bg-mammo-card2 bg-fit-card-one p-0 container-340 '>
          <div className="mt-auto mb-2">
            <div className='card-thanks text-white card-help-body mb-1 p-1'>
              <p className="mb-1 normal_text">
                Make your health a priority!</p>
              <ul>
                <li className="mb-1 normal_text">
                Learn more about your screening & scheduling options.
                </li>
                <li className="mb-1 normal_text" >
                If you’ve already scheduled or recently completed your mammogram, please disregard this message.
                </li>
              </ul>
            </div>
            <div className='d-flex flex-column gap-3'>
              <div className='align-self-end mr-2 navigation_img_wrapper position-relative' >
              <Link to={`/${company}/${campaignId}/mammo/summary`}>
                <img className='me-2 navigation_img' src= "/Next_white.svg"height="40" width="100" alt="Next Dark" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Export the component
export default Mamo;