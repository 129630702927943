import { createSelector } from 'reselect';

const appointmentReducer = (state) => state.appointments

export const selectAppointment = createSelector(
    [appointmentReducer],
    (appointments) => appointments.appointment
);

export const allBookedAppointment = createSelector(
    [appointmentReducer],
    (appointments) => appointments.bookedAllAppointment
);

export const userBookedPharmacyAppointment = createSelector(
    [appointmentReducer],
    (appointments) => appointments.bookedPharmacyAppt
);


export const scheduler = createSelector(
    [appointmentReducer],
    (appointments) => appointments.scheduler
);


export const selectBookedAppointment = createSelector(
    [appointmentReducer],
    (appointments) => appointments.booked
)
