import React from "react";

const HorizontalLine = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div key={ind}
            className="mb-3"
            style={{
              backgroundColor: item?.lineColor ? item?.lineColor : "#677083",
              height: "1px",
            }}
          ></div>
        ))}
    </div>
  );
};

export default HorizontalLine;
