import axios from "axios";

export const getTemplateMetaData = (data) => {
    return {
      type: 'GET_TEMPLATE_META_DATA',
      payload: data,
    };
  };

  export const setTemplateMetaData = (data) => {
    return {
      type: 'SET_TEMPLATE_META_DATA',
      payload: data,
    };
  };


  export const setUserCampaignData = (data) => {
    return {
      type: 'SET_USER_CAMPAIGN_DATA',
      payload: data,
    };
  };
  export const getUserCampaignData = (campCode, data) => {
    return {
      type: 'GET_USER_CAMPAIGN_DATA',
      payload: {campCode, data},
    };
  };
