import React from 'react'

const SubHeader = ({subheader, userContext, locationDetails}) => {
  let subHeaderText = "";
  if(subheader){
    subHeaderText = subheader?.Value.replace("#ApptTime#",userContext?.ApptTM);
    subHeaderText = subHeaderText.replace("#ApptDate#",userContext?.ApptDT);
    if(locationDetails){
      let location = locationDetails.FacName;
      subHeaderText = subHeaderText.replace("#ApptLocation#","</br>"+location);
    }
    else{
      subHeaderText = subHeaderText.replace("#ApptLocation#","");
    }
  }
  return (
    <>
    <div className='d-flex align-items-start'>
      { subheader?.Img &&
        <img className={"align-top mt-2 " + subheader?.ImgClass} src={"../../../../" + subheader?.Img} />
      }
      <span className={'sub-header fs-6 ' + subheader?.CssClass} dangerouslySetInnerHTML={{__html: subHeaderText}}></span>
    </div>
    </>
  )
}

export default SubHeader
