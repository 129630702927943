import React from 'react'
import './style.css'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { tokenError } from '../../redux/store/loader/loaderSelector';
import { setTokenError } from '../../redux/store/loader/loaderAction';


const TokenErrorLoader = () => {
    const tokenErrorMessage = useSelector(tokenError);
    const dispatch = useDispatch()
    return (
        <div>
            <div
                className="modal show"
                style={{ position: "fixed", top: '70%', left: '0', bottom: '0', right: '0', margin: 'auto', display: 'block', width: "200px", }}
            >
                <Modal.Dialog>
                    <Modal.Body>
                        <p className="text-center text-blue">{tokenErrorMessage}</p>
                        <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => dispatch(setTokenError(''))}>
                            <button style={{ border: '1px solid #19406f', padding: '5px 30px', borderRadius: '40px' }}>Close</button>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </div>

        </div>
    )
}

export default TokenErrorLoader