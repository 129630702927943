export const setLoader = (data) => {
    return {
        type: 'SET_LOADER',
        payload: data,
    };
};

export const setError = (data) => {
    return {
        type: 'SET_ERROR',
        payload: data,
    };
};

export const setTokenError = (data) => {
    return {
        type: 'SET_TOKEN_ERROR',
        payload: data,
    };
};