import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
import {
  getAppointment,
  getBookAppointment,
  getPharmacyBookedAppointment,
  getScheduler,
} from "../appointment/getAppointment";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";

export function* getVerifyToken(data) {
  const config = {
    headers: {
      Authorization: `Bearer ${data.payload.token}`,
    },
  };
  try {
    //const { data: { token } } = yield axios.get(`${process.env.REACT_APP_OUTREACH_BASE_URL}/verify?id=${data.payload.token}&schedulerId=${data.payload.campCode}`, config);
    const token = data.payload.token;
    localStorage.setItem("token", token);
    localStorage.setItem("scheduleCode", data.payload.scheduleCode);
    // yield put(getBookAppointment(data.payload))
    yield put(getPharmacyBookedAppointment(data.payload));
    yield put(getScheduler(data.payload));
    yield put(getAppointment(data.payload));
    console.log("success");
  } catch (error) {
    console.log("ERR::", error);
    if (error.response.status === 401) {
      yield put(
        setTokenError(
          "This link is no longer valid. Please call 425-260-6684 to arrange your appointment"
        )
      );
    } else {
      yield put(
        setError(
          "An error occurred while processing your request, please contact admin"
        )
      );
    }
    yield put(setLoader(false));
    console.log("error", error.message);
  }
}

export function* onGetVerifyToken() {
  yield takeLatest("GET_VERIFY", getVerifyToken);
}

export function* verifyTokenSagas() {
  yield all([call(onGetVerifyToken)]);
}
