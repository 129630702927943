import React, { useEffect, useRef, useState, useMemo } from 'react';
import Play from '../../assets/images/PLAY-20240716-235257.svg';
import Pause from '../../assets/images/PAUSE-20240716-235246.svg';
import Mute from '../../assets/images/MUTE-20240716-235245.svg';
import UnMute from '../../assets/images/UNMUTE-20240716-235245.svg';
import Replay from '../../assets/images/REPLAY-20240716-235246.svg';
import Replay_2 from '../../assets/images/REPLAY_2.svg';
import './index.css';
import { useDispatch,  } from "react-redux";
import { useLocation,  } from "react-router-dom";
import {addFitResp, } from "../../redux/store/fit/fitAction";
import {setError, setLoader,} from "../../redux/store/loader/loaderAction";
import { decodeToken } from "../../utils";
import Thumbnail from "../../assets/images/thumbnail.png";

const FitPrimer = () => {
  const videoRef = useRef(null);
  const divRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true); 
  const [isMuted, setIsMuted] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isShowingVideo, setIsShowingVideo] = useState(true);
  const [progress, setProgress] = useState(0);
  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  useEffect(() => {
    setIsShowingVideo(true);
    dispatch(setError(""));
    const token = queryParams.get("token");
    var data = decodeToken(token);

    if (!token) {
      dispatch(setError("token is missing. Please contact Admin"));
      dispatch(setLoader(false));
      return;
    } else {
      localStorage.setItem("token", token);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  },[]);

  const togglePlayPause = () => {
    setIsEnded(false);
    if (videoRef?.current) {
      if (isPlaying) {
        videoRef?.current?.pause();
      } else {
        let playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            videoRef.current.play();
          })
          .catch(error => {
            console.log(error);
          });
        }
        setIsShowingVideo(true);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMuteUnmute = () => {
    if (videoRef?.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const replayVideo = () => {
    if (videoRef?.current) {
      setIsShowingVideo(true);
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setIsEnded(false);
      setIsPlaying(true);
    }
  };

  const handleWatchFITKitVideo = (resDe) => {
    dispatch(setLoader(true));
    const token = queryParams.get("token");
    const user = decodeToken(token);
    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
    };
    dispatch(addFitResp(data));
  };

  const handleLoadingStart = (e) => {
    setLoading(true)
  }

  const handleLoadingEnd = (e) => {
    handleWatchFITKitVideo("Y");
    setLoading(false)
  }

  const handleVideoEnd = () => {
    setIsEnded(true); 
    setIsPlaying(false);
    setIsShowingVideo(false);
  }

  const handleTimeUpdate = (e) => {
    if (isNaN(e.target.duration)){
      return;
    }else{
      setProgress((e.target.currentTime / e.target.duration) * 100);
    }
  }

  const getWrapperStyle = () => {
    let height = windowSize.height;
    let width = windowSize.width;
    return {height: `${height-43}px`, width: `${width}px`}
  }

  let wrapperStyle = useMemo(() => getWrapperStyle(), [windowSize.height, windowSize.width]);

  const getVideoStyle = () => {
    let height = windowSize.height;
    let width = windowSize.width;
    return {height: `${height-88}px`, objectFit: "contain", background: "#000"}
  }

  let videoStyle = useMemo(() => getVideoStyle(), [windowSize.height, windowSize.width]);

  return (
    <div className='container-fluid g-0 '>
      <div className='card card-custom card-help'>
        <div className='card-header bg-inky text-white text-center'>
          <img alt='logo' className='w-15' src='../../../../Logo.svg' />
        </div>
        <div 
          style={{ position: 'relative', flexGrow: 1 }}
        >
            {loading && 
              <div className='retry_flag'>
                <div className='retry_flag_inner'>
                  <span className='retry_flag_txt'>Loading....</span>
                </div>
              </div>
            }

            {isEnded && 
              <div className='retry_flag'>
                <div className='retry_flag_inner' onClick={() => replayVideo()}>
                  <img src={Replay} alt="Replay" className='retry_flag_img' />
                  <span className='retry_flag_txt'>Replay</span>
                </div>
              </div>
            }

            <div 
              style={wrapperStyle}
            >
              {!isShowingVideo  &&
                <div className='primer_video_wrapper'>
                  <img alt="thumbnail" src={Thumbnail} className="video flex-grow-1" style={{...videoStyle, objectFit: "cover"}} />
                </div>
                
              }

              <div className={`primer_video_wrapper ${!isShowingVideo && "d-none"}`}>
                <video
                 style={videoStyle}
                  playsInline
                  ref={videoRef}
                  autoPlay={true}
                  loop={false}
                  muted={isMuted}
                  className={`video video_fit ${!isShowingVideo && "d-none"}`}
                  onLoadStart={(e) => handleLoadingStart(e)}
                  onLoadedData={(e) => handleLoadingEnd(e)}
                  onEnded={() => handleVideoEnd()}
                  poster={Thumbnail}
                  onTimeUpdate={(e) => handleTimeUpdate(e)}
                >
                  <source src="https://elxovoiceurl.blob.core.windows.net/elxovideos/FIT_PRIMER_v003.mp4" type="video/mp4" />
                </video>
                <div className='controls_container'>
                <progress id="progress" max="100" value={progress} className='primer_video_progress'>
                  Progress
                </progress>
                <div className="controls" ref={divRef}>
    
              <div className="button-container overflow-hidden gap-3">
                <div
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    overflow: "hidden"
                  }}
                >
                  <button style={{
                    width: "95%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2
                  }} onClick={togglePlayPause}>
                    <img
                      style={{ height: 16, width: 16 }}
                      src={isPlaying ? Pause : Play}
                      alt={isPlaying ? "Pause" : "Play"}
                    />
                    <p className='text-uppercase line-height-normal ctrl_text'>
                      {isPlaying ? 'Pause' : 'Play'}
                    </p>
                  </button>
                </div>
              </div>
              <div className="button-container overflow-hidden gap-3">
                <div
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    overflow: "hidden"
                  }}
                >
                  <button style={{
                    width: "95%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2, 
                  }} onClick={replayVideo}>
                    <img
                      style={{ height: 16, width: 16 }}
                      src={Replay_2}
                      alt={"Replay"}
                    />
                    <p className='text-uppercase line-height-normal ctrl_text'>
                      {'Replay'}
                    </p>
                  </button>
                </div>
              </div>
              <div className="button-container">
                <div
                  style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    overflow: "hidden"
                  }}
                >
                  <button
                    style={{
                      width: "95%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2
                    }}
                    onClick={toggleMuteUnmute}>
                    <img
                      style={{ height: 16, width: 16 }}
                      src={isMuted ? Mute : UnMute}
                      alt={isMuted ? "Mute" : "Unmute"}
                    />
                    <p className='text-uppercase line-height-normal ctrl_text'>
                      {isMuted ? 'Unmute' : 'Mute'}
                    </p>
                  </button>
                </div>
              </div>
            </div>
</div>


              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitPrimer;
