import { combineReducers } from 'redux';
import appointmentReducer from './appointment/appointmentReducer';
import verifyTokenReducer from './verifyToken/verifyReducer';
import loaderReducer from './loader/loaderReducer';
import mtmReducer from './mtm/mtmReducer';
import fitReducer from './fit/fitReducer';
import mammoReducer from './mam/mammoReducer';
import comReducer from './common/comReducer';
import templateReducer from './template/templateReducer';
const rootReducer = combineReducers({
  appointments: appointmentReducer,
  verifyToken: verifyTokenReducer,
  loader: loaderReducer,
  mtm: mtmReducer,
  fit: fitReducer,
  mammo: mammoReducer,
  templateReducer, 
  comReducer
});

export default rootReducer;
