import React from 'react'

const Success = ({ data }) => {
    return (
        <div className='success-main'>
            <div>  <svg class="right-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
            </svg></div>
            <div>{data.Value}</div>
        </div>
    )
}

export default Success