import React, { useEffect, useState } from 'react'
import { json } from 'react-router-dom';

const CheckList = ({ data, userOptions}) => {

    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        if(data && userOptions){
            let elements = JSON.parse(JSON.stringify(data.Elements));
            elements.map(x => {
                let userOption = userOptions.filter(k => k.QstId == x.QstId);
                if(userOption && userOption.length > 0){
                    if(x.Answers){
                        x.ReviewCompleted = false;
                        x.Answers.map(answer => {
                            if(answer == userOption[0].UserRespCode){
                                x.ReviewCompleted = true;        
                            }
                        })
                    }
                    else{
                        x.ReviewCompleted = userOption[0].UserRespCode == "Yes";
                    }
                }
                else{
                    x.ReviewCompleted = false;
                }
            })
            let elementsWithStatus = JSON.parse(JSON.stringify(elements));
            setStatusList(elementsWithStatus);
        }
        else if(data){
            setStatusList([...data.Elements]);
        }
    },[data, userOptions])

    return (
        <>
            <div className='check-list text-blue'>
                <div className='check-list-detail'>
                    <div className='check-list-title fw-bold'>{data.Header}</div>
                    <div className='check-list-title f-12'>{data.ChkLabel}</div>
                </div>
                {statusList.map((item) => {
                    return (
                        <div className='check-list-detail'>
                            <span className='check-list-description'>{item.Text}</span>
                            <input type="checkbox" checked={item.ReviewCompleted}/>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default CheckList