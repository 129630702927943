import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import MapItem from "./MapItem";

const MapComponent = ({ data }) => {
  const { values } = data;

  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return(
            <MapItem key={ind} item={item} ind={ind} />
        )})}
    </div>
  );
};

export default MapComponent;
