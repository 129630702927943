import React from "react";

const Intro = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      <div>
        {values &&
          values.length > 0 &&
          values?.map((item, ind) => {
            return (
              <div key={ind}>
                <h2 
                  dangerouslySetInnerHTML={{
                    __html: item?.headerTextValue,
                  }}
                  className="editor-inner-text"
                  style={{
                    color: item?.headerTextColor,
                    fontSize: item?.headerTextSize || "14px",
                    marginBottom: "4px",
                    fontWeight: "600",
                  }}
                />
                <p 
                  dangerouslySetInnerHTML={{
                    __html: item?.bodyText || "body text",
                  }}
                  className="editor-inner-text"
                  style={{
                    color: item?.bodyTextColor,
                    fontSize: item?.bodyTextSize
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Intro;
