import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import './style.css'

const Calendar = ({ show, setShow, date, endDate, setDate, minDate }) => {
    const onChange = (dates) => {
        const [start, end] = dates;
        setDate(start)
        setShow(false)
    };
    return (
        <div className='calendar'>
            <Modal size="sm" show={show} onHide={() => setShow(false)}>
                <Modal.Body className='p-2'>
                    <ReactDatePicker
                        selected={date}
                        formatWeekDay={nameOfDay => nameOfDay.substr(0,3)}
                        onChange={onChange}
                        maxDate={endDate}
                        selectsRange
                        inline
                        minDate={minDate}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Calendar