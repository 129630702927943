import React from "react";
import "../TemplateComponents/helpers/style.css";

const ButtonForm = ({ data, handleSave, handleBtnNavigate, previewData  }) => {
  const { values } = data;

  const handleButtonClick = (item) => {
    const stringId = String(item?.id)
    if( item?.buttonType === "text" &&  item?.nextPage !== ""){
      handleBtnNavigate(item?.nextPage)
      handleSave(
        {
          parentId: data?.id,
          id: stringId,
          clicked: "N",
        },
        data.type
      );
    }else if ( item?.buttonType === "link" && item?.buttonLink && item?.buttonLink !== "") {
      handleSave(
        {
          parentId: data?.id,
          id: stringId,
          clicked: "Y",
        },
        data.type
      );
      window.open(item?.buttonLink, "_blank");
    } 
  };

  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item,) => {

    const baseStyles = {
      position: "absolute",
      zIndex: 0,
      height: isFitToScreen ? "600px" : backgroundHeight,
      width: isFitToScreen ? "336px" : backgroundWidth,
      backgroundColor: backgroundColor,
    };

    switch (align) {
      case "top left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "middle left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "bottom left": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom center": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom right": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      default:
        return baseStyles;
    }
  };

  return (
    <div className="control_padding">
      <div>
        {values &&
          values.length > 0 &&
          values?.map((item, ind) => (
            <button
               onClick={() => handleButtonClick(item)}
              className="btn_form_btn"
              style={{
                ...getImageStyles(item.align, item?.isFitToScreen, item?.backgroundHeight, item?.backgroundWidth, item?.background, item,),
                borderRadius: item?.buttonRounding ? `${item.buttonRounding}px` : "",
                opacity: item?.opacity,
                border: `1px solid ${item?.borderColor}`,
                height: item?.ySize === "px" ? "auto" : `${item?.ySize}`,
                width: item?.xSize === "px" ? "auto" : `${item?.xSize}`,
                background: item?.background,
              }
              }
              key={ind}
            >
              {item?.text ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.text,
                  }}
                  style={{
                    fontFamily: item?.buttonFont,
                    fontSize: item?.buttonFontSize,
                    paddingLeft: item?.paddingLeft ? `${item.paddingLeft}px` : "",
                    paddingRight: item?.paddingRight ? `${item.paddingRight}px` : "",
                    paddingTop: item?.paddingTop ? `${item.paddingTop}px` : "",
                    paddingBottom: item?.paddingBottom ? `${item.paddingBottom}px` : "",
                  }}
                  className="editor-inner-text"
                />
              ) : (
                "Button"
              )}
            </button>
          ))}
      </div>
    </div>
  );
};

export default ButtonForm;
