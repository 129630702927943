import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";
import { setFitResponse, getFitResponseAction} from "./fitAction";
import { decodeToken } from '../../../utils';

export function* addFitResponse(data) {
  const token = localStorage.getItem('token')
  if(data === undefined)
    return;
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    yield axios.post(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/SaveCampaignResponse`, data.payload, config);
    yield put(setLoader(false))
    const data1 = decodeToken(token);
    if (data1) {
        yield put(getFitResponseAction({ id: data1['uid'], campCode: data1['campCode'] }))
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
  }
}

export function* getFitResponse(data) {
  const token = localStorage.getItem('token')
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  };
  try {
    const fitResponse = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetUserCampaign?campaignCode=${data.payload.campCode}&userGuid=${data.payload.id}`, config);
    yield put(setFitResponse(fitResponse.data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."))
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}


export function* onAddFitResponse() {
  yield takeLatest("ADD_FIT_RESP", addFitResponse);
}

export function* onGetFitResponse() {
  yield takeLatest("GET_FIT_RESP", getFitResponse);
}

export function* fitSagas() {
  yield all([call(onAddFitResponse), call(onGetFitResponse)]);
}
