import React from "react";

const Header = ({ data }) => {
  const values = data;
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div
            key={ind}
            style={{
              color: item?.fontColor,
              opacity: item?.opacity,
              textAlign: item?.headerTextAlign,
              marginBottom: "10px",
              fontSize: item?.height || "12px",
            }}
          >
            <h2
              dangerouslySetInnerHTML={{
                __html: item?.headerText || "Header",
              }}
              className="editor-inner-text"
              style={{
                background: item?.background,
                width: item?.width || "100%",
                border: `1px solid ${item?.borderColor || "#000"}`,
                margin: "4px 0px",
                position: item?.xTextPosition ? "absolute" : "relative",
                top: item?.xTextPosition ? item?.xTextPosition : "10",
                [item?.headerTextAlign || "left"]: item?.yTextPosition
                  ? item?.yTextPosition
                  : "0px",
                fontSize: item?.height || "12px",
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default Header;
