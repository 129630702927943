import React, { useEffect, useState } from 'react'
import './style.css'
import Modal from 'react-bootstrap/Modal';
import { XCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { error } from '../../redux/store/loader/loaderSelector';


const ErrorLoader = () => {
    const errorMessage = useSelector(error)
    return (
        <div>
            <div
                className={`modal show`}
                style={{ display: 'block', width: "200px", position: "fixed", marginTop: "50%", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
            >
                <Modal.Dialog>
                    <Modal.Body>
                        <p className="text-center text-red">{errorMessage}</p>
                        <div className='d-flex justify-content-center '>
                            <XCircle color='red' size={30} />
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </div>
    )
}

export default ErrorLoader