import React from 'react'

const Image = ({data}) => {
  return (
    <div className='image-main'>
        <div className='image-text'>{data.text}</div>
        <img src={data.image} alt="bncf" height='100' width='100'/>
    </div>
  )
}

export default Image