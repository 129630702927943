import React, { useState, useEffect } from "react";
import { decodeToken } from "../../../utils";
import { useLocation } from "react-router-dom";
import axios from "axios";

const TextBox = ({ data, handleSave, responses, setResponses  }) => {
  const { id, values } = data;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const dataDecode = decodeToken(token);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    const initialInputValues = values?.reduce((acc, item) => {
      acc[item?.id] = item?.responseDe || "";
      return acc;
    }, {});
    setInputValues(initialInputValues);
  }, [values]);

  const handleChange = (parentId, id, question, e) => {
    const stringId = String(id);
    const newResponses = responses?.filter(response => response?.id !== stringId);
    newResponses.push({
      parentId: String(parentId),
      id: stringId,
      question,
      result: e.target?.value
    });
    setResponses(newResponses);
    setInputValues((prevValues)=> ({
      ...prevValues, [id]: e.target?.value,
    }))
  };

  const saveAppointment = async (e, textItem) => {
    handleSave({
      parentId: data.id,
      id: String(textItem?.id),
      question: textItem?.text ? textItem?.text : "",
      result: e.target?.value,
    }, data.type);
  };

  return (
    <div className="control_padding">
      {/* {values &&
        values.length > 0 &&
        values.map((item, ind) => {
          return(
          <div
            key={ind}
            className=""
            style={{
              position: item?.xPosition ? "absolute" : "relative",
              top: item?.xPosition ? item.xPosition : "10px",
              [item?.align || "left"]: item?.yPosition ? item.yPosition : "0px",
              fontFamily: item?.textFont,
              color: item?.color,
              marginBottom: "8px",
            }}
          >
            <label
              style={{
                fontSize: item?.fontSize,
                margin: "0 8px 0 0",
                maxWidth: "50%",
                display: "block",
                width: "100%"
              }}
              dangerouslySetInnerHTML={{
                __html: item?.text || "Text Box",
              }}
              className="editor-inner-text"
            ></label>
            <input 
              onChange={(e) => handleChange(id, item.id, item.text, e)}
              onBlur={(e) => saveAppointment(e, item)}
              value={inputValues[item.id] || ""}
              style={{
                borderColor: item?.border,
                background: item?.background,
                opacity: item?.opacity,
                height: item?.height,
                width: item?.width,
                borderRadius: item?.borderRadius,
                display: "block",
                width: "100%",
                borderRadius: '4px'
              }}
            />
          </div>
        )}
        )} */}

        {values &&
                            values.length > 0 &&
                            values?.map((item, ind) => (
                              <div
                                key={ind}
                                className=""
                                style={{
                                  position: item?.xPosition
                                    ? "absolute"
                                    : "relative",
                                  top: item?.xPosition ? item?.xPosition : "10px",
                                  [item?.align || "left"]: item?.yPosition
                                    ? item.yPosition
                                    : "0px",
                                  fontFamily: item?.textFont,
                                  color: item?.color,
                                  // display: "flex",
                                  marginBottom: "8px",
                                  verticalAlign: item?.align
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: item?.fontSize,
                                    margin: "0 8px 0 0",
                                    // maxWidth: "50%",
                                    display: "block",
                                    width: "100%"
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text || "Text Box",
                                  }}
                                  className="editor-inner-text"
                                ></label>
                                <input
                                 onChange={(e) => handleChange(id, item.id, item.text, e)}
                                    onBlur={(e) => saveAppointment(e, item)}
                                    value={inputValues[item.id] || ""}
                                  style={{
                                    borderColor: item?.border,
                                    background: item?.background,
                                    opacity: item?.opacity,
                                    height: item?.height,
                                    width: item?.width,
                                    borderRadius: item?.borderRadius,
                                    display: "block",
                                    width: "100%",
                                    borderRadius: '4px'
                                  }}
                                />
                              </div>
                            ))}
    </div>
  );
};

export default TextBox;
