import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";


const MapItem = ({ item, ind }) => {
    const [currentLocation, setCurrentLocation] = useState({});
    const [mapLocation, setMapLocation] = useState(null);
    const [showInfo, setShowInfo] = useState(null);
    // const [mapLocation, setMapLocation] = useState({ lat: 47.6186309, lng: -122.1867907 });


    useEffect(() => {
        const getAddressCoordinates = async (address) => {
            try {
                getGeocode({ address }).then((results) => getLatLng(results[0])).then(({ lat, lng }) => {
                    let tempObj = { lat, lng };
                    setMapLocation({ lat: lat, lng: lng });
                })
            } catch (error) {
                console.error('Error geocoding address:', error);
            }
        };

        let address = "";
        let tempElement = document.createElement('div');
        tempElement.innerHTML = item?.addressDecs;
        let paragraphElement = tempElement?.querySelector('p');
        if (paragraphElement) {
            address = paragraphElement?.textContent?.trim();
        }
        setShowInfo(address);

        if (address) {
            getAddressCoordinates(address);
        }

    }, [item])

    const mapContainerStyle = {
        width: '100%',
        height: '300px',
        borderRadius: "12px"
    };

    const mapPlaceholder = {
        backgroundColor: "#edeaea"
    }

    const combinedStyle = { ...mapContainerStyle, ...mapPlaceholder };

    return (
        <div key={ind} className="mb-2">
            <p
                style={{
                    fontSize: "1rem",
                    lineHeight: "20px",
                    marginTop: "8px",
                    color: item?.headerColor,
                }}
            >
                Date and Time :{" "}
            </p>
            <p
                dangerouslySetInnerHTML={{
                    __html: item?.dateAndTimeDesc,
                }}
                style={{
                    fontSize: "1rem",
                    lineHeight: "20px",
                    marginTop: "8px",
                    marginBottom: "8px",
                    textAlign: "center",
                    color: item?.headerColor,
                }}
            />

            <div className="mt-2 px-1">
                {mapLocation ?
                    <GoogleMap
                        zoom={8}
                        mapContainerStyle={mapContainerStyle}
                        center={mapLocation}
                    >
                        <Marker
                            position={mapLocation}
                            // icon={null}
                        >
                        </Marker>
                    </GoogleMap>
                    :
                    <div style={combinedStyle} >
                    </div>
                }
            </div>

            <div className="d-flex mt-2">
                <p
                    dangerouslySetInnerHTML={{
                        __html: item?.addressDecs,
                    }}
                    style={{
                        fontSize: "1rem",
                        lineHeight: "20px",
                        marginTop: "8px",
                        width: "70%",
                        color: item?.headerColor,
                    }}
                />
                <p
                    style={{
                        fontSize: "1rem",
                        lineHeight: "20px",
                        marginTop: "8px",
                        textAlign: "center",
                        width: "30%",
                        color: item?.headerColor,
                    }}
                >
                    <div
                        className={`direction_icon ${item?.headerColor === "#92CCF0"
                            ? "blue"
                            : item?.headerColor === "#F0F7FA"
                                ? "paleBlue"
                                : "dolphin"
                            }`}
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    ></div>
                    Direction
                </p>
            </div>
        </div>
    )
}


export default MapItem;