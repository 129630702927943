import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionButton, ActionHeader, Background, BulletText, ButtonForm, CancelButton,
  CheckList, ColoHeader, DuelGraphic, Header, HorizontalLine, IconAction,
  Intro, MapComponent, Navigation, Question, SingleGraphic, SubHeader, Text, TextBox,
  TextGraphic, Video
} from "./TemplateComponents/helpers";
import { decodeToken } from "../../utils";
import { getTemplateMetaData, getUserCampaignData } from "../../redux/store/template/templateActions";
import axios from "axios";
import '../components/TemplateComponents/helpers/style.css'
import { setLoader } from "../../redux/store/loader/loaderAction";

const Container = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [bgData, setBgData] = useState({});
  const [navData, setNavData] = useState([]);
  const [responses, setResponses] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [selectedButton, setSelectedButton] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  localStorage.setItem("token", token);
  const dataDecode = decodeToken(token);
  const tmData = useSelector((state) => state.templateReducer.tmData);
  const getUserCampData = useSelector((state) => state.templateReducer.getUserCampData);

  useEffect(() => {
    if (tmData && tmData.templateMetaData) {
      const parsedData = JSON.parse(tmData.templateMetaData);
      setPreviewData(parsedData)
      setPageData(parsedData[`page${currentPage}`] || []);
    }
  }, [tmData, currentPage]);

  useEffect(() => {
    if (token) {
      const data = decodeToken(token);
      let userGuid = dataDecode.UserId;
      let campaignCode = dataDecode.campCode;
      if (data) {
        dispatch(getTemplateMetaData({ id: data["uid"], campCode: data["campCode"] }));
        dispatch(getUserCampaignData(campaignCode, userGuid));
      }
    }
  }, [dataDecode.UserId, dataDecode.campCode, dispatch, token]);

  useEffect(() => {
    let bgItem = pageData?.find((item) => item?.type === "Background");
    let navItem = pageData?.find((item) => item?.type === "Navigation");
    let bgValue = bgItem?.values?.find((item) => item?.imageData);
    let navValue = navItem?.values?.find((item) => item);
    setBgData(bgValue ? bgValue : {});
    setNavData(navValue ? navValue : {});
  }, [pageData]);



  const handleSave = (actionButtonData, type,) => {
    const stateToUse = checkboxState;
    let responseDesc = [];
    const duplicateData = (array, newItem) => {
      return array.some((item) => item.id === newItem.id && item.parentId === newItem.parentId);
    };

    const addUniqueResponses = (sourceArray) => {
      sourceArray.forEach((item) => {
        if (item.result !== null && item.question !== null && !duplicateData(responseDesc, item)) {
          responseDesc.push(item);
        }
      });
    };

    const extractResponsesFromState = (state) => {
      for (const parentId in state) {
        for (const questionId in state[parentId]) {
          const { question, result } = state[parentId][questionId];
          if (result !== null && question !== null) {
            responseDesc.push({ parentId, id: questionId, question, result });
          }
        }
      }
    };

    const filterAndAddActionData = (actionData, type) => {
      if (actionData) {
        if (type === "Checklist") {
          responseDesc = responseDesc.filter((item) => item.id !== actionData.id);
        } else {
          responseDesc = responseDesc.filter((item) => item.parentId !== actionData.parentId);
        }
        responseDesc.push(actionData);
      }
    };

    extractResponsesFromState(stateToUse);
    addUniqueResponses(responses);

    if (getUserCampData && getUserCampData.responseDesc) {
      addUniqueResponses(getUserCampData.responseDesc);
    }

    filterAndAddActionData(actionButtonData, type);

    const payload = {
      userGuid: dataDecode.UserId,
      campaignCode: dataDecode.campCode,
      responseDesc,
    };

    saveAppointment(payload);
  };

  const saveAppointment = async (payload) => {
    let bearerToken = localStorage.getItem("token");
    const config = { headers: { Authorization: `Bearer ${bearerToken}` } };
    let userGuid = dataDecode.UserId;
    let campaignCode = dataDecode.campCode;
    const data = decodeToken(token);
    try {
      dispatch(setLoader(true))
      const res = await axios.post(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/SaveCampaignResponse`, payload, config);
      if (res.status === 200) {
        dispatch(getTemplateMetaData({ id: data["uid"], campCode: data["campCode"] }));
        dispatch(getUserCampaignData(campaignCode, userGuid));
        dispatch(setLoader(false))

      }
    } catch (error) {
      dispatch(setLoader(false))
      console.error("Error booking appointment:", error);
    }
  };

  const handleSelectNextPrevPage = (page, data) => {
    let findObj = data && data.length > 0 && data.find((elem) => elem.type === "Page");
    let nextPageNo = findObj?.values[0]?.nextPage;
    let prevPageNo = findObj?.values[0]?.previousPage;

    const tempPreviewData = { ...previewData };
    let currentPageNum = Number(tempPreviewData.currentPage?.replace("page", ""));
    if (page === "prev") {
        currentPageNum = prevPageNo ? prevPageNo : currentPageNum - 1;
    } else {
        currentPageNum = nextPageNo ? nextPageNo : currentPageNum + 1;
    }
    const newPage = `page${currentPageNum}`;
    
    if (tempPreviewData[newPage]) {
        setPreviewData({ ...previewData, currentPage: newPage });
        setPageData(tempPreviewData[newPage] || []);
    }
};

  const handleBtnNavigate = (page) => {
    const tempPreviewData = { ...previewData };
    const newPage = `page${page}`;
    if (tempPreviewData[newPage]) {
        setPreviewData({ ...previewData, currentPage: newPage });
        setPageData(tempPreviewData[newPage] || []);
    }
};
  

  const renderComponent = (item) => {
    switch (item.type) {
      case "Action Header":
        return <ActionHeader key={item.id} data={item} />;
      case "Action Button":
        return <ActionButton key={item.id} data={item} handleSave={handleSave} selectedButton={selectedButton} setSelectedButton={setSelectedButton} />;
      case "BulletText":
        return <BulletText key={item.id} data={item} />;
      case "Button":
        return <ButtonForm key={item.id} data={item} previewData={previewData} handleSave={handleSave} handleBtnNavigate={handleBtnNavigate} />;
      case "Cancel Button":
        return <CancelButton key={item.id} data={item} />;
      case "Checklist":
        return <CheckList key={item.id} data={item} checkboxState={checkboxState} setCheckboxState={setCheckboxState} handleSave={handleSave} />;
      case "Colo Header":
        return <ColoHeader key={item.id} data={item} />;
      case "Duel Graphic":
        return <DuelGraphic key={item.id} data={item} />;
      case "Header":
        return <Header key={item.id} data={item.values} />;
      case "Navigation":
        return <Navigation key={item.id} previewData={previewData} handleSelectNextPrevPage={handleSelectNextPrevPage} setPreviewData={setPreviewData} pageData={pageData} setPageData={setPageData} data={item}  currentPage={currentPage} />;
      case "Horizontal Line":
        return <HorizontalLine key={item.id} data={item} />;
      case "Icon Action":
        return <IconAction key={item.id} data={item} />;
      case "Intro":
        return <Intro key={item.id} data={item} />;
      case "Map":
        return <MapComponent key={item.id} data={item} />;
      case "Question":
        return <Question key={item.id} data={item} />;
      case "Single Graphic":
        return <SingleGraphic key={item.id} data={item} />;
      case "Sub Header":
        return <SubHeader key={item.id} data={item} />;
      case "Text":
        return <Text key={item.id} data={item} />;
      case "Text Graphic":
        return <TextGraphic key={item.id} data={item} />;
      case "Text Box":
        return <TextBox key={item.id} data={item} responses={responses} setResponses={setResponses} saveAppointment={saveAppointment} handleSave={handleSave} />;
      case "Video":
        return <Video key={item.id} data={item} handleSave={handleSave} />;
      default:
        return null;
    }
  };


  const getImageStyles = (align, isFitToScreen, backgroundHeight, backgroundWidth, backgroundColor = "", item, type, isWidth=true) => {
    let baseStyles = {
      position: item?.align ? "absolute" : "relative",
      zIndex: 0,
      height: isFitToScreen ? "600px" : (backgroundHeight && backgroundHeight !== "px") ? backgroundHeight : "300px",
      width: isFitToScreen ? "336px" : (backgroundWidth && backgroundWidth !== "px") ? backgroundWidth : "150px",
      backgroundColor: (type != "Button") && backgroundColor,
    };
    
    if(isWidth){
      baseStyles = {
        position: item?.align ? "absolute" : "relative",
        zIndex: 0,
        height: isFitToScreen ? "600px" : (backgroundHeight && backgroundHeight !== "px") ? backgroundHeight : "300px",
        width: isFitToScreen ? "336px" : (backgroundWidth && backgroundWidth !== "px") ? backgroundWidth : "150px",
        backgroundColor: (type != "Button") && backgroundColor,
      };
    }else{
      baseStyles = {
        position: item?.align ? "absolute" : "relative",
      };
    }
    

    switch (align) {
      case "top left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "top right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: 0,
          right: 0,
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "middle left": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle center": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "middle right": {
        const marginTop = item?.marginTop || 0;
        const marginBottom = item?.marginBottom || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginTop = marginTop - marginBottom;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          marginTop: `${adjustedMarginTop}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      case "bottom left": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom center": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginLeft = item?.marginLeft || 0;
        const marginRight = item?.marginRight || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginLeft = marginLeft - marginRight;

        return {
          ...baseStyles,
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: `${adjustedMarginBottom}px`,
          marginLeft: `${adjustedMarginLeft}px`
        };
      }
      case "bottom right": {
        const marginBottom = item?.marginBottom || 0;
        const marginTop = item?.marginTop || 0;
        const marginRight = item?.marginRight || 0;
        const marginLeft = item?.marginLeft || 0;

        const adjustedMarginBottom = marginBottom - marginTop;
        const adjustedMarginRight = marginRight - marginLeft;

        return {
          ...baseStyles,
          bottom: 0,
          right: 0,
          marginBottom: `${adjustedMarginBottom}px`,
          marginRight: `${adjustedMarginRight}px`
        };
      }
      default:
        return baseStyles;
    }
  };

  const getPaddingStyle = (item) => {
    let paddings = {
      paddingTop: item?.paddingTop ? `${item?.paddingTop}px` : "0px",
      paddingRight: item?.paddingRight ? `${item?.paddingRight}px` : "0px",
      paddingBottom: item?.paddingBottom ? `${item?.paddingBottom}px` : "0px",
      paddingLeft: item?.paddingLeft ? `${item?.paddingLeft}px` : "0px",
    }

    return { ...paddings }
  }




  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help"
      >
        <div
          className="card-body py-3 card-body-h-vh p-0 container-340 d-flex flex-column justify-content-between"
          style={{
          ...(bgData?.isFitToScreen
            ? {
                backgroundImage: `url(${bgData?.imageData})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              
              }
            : {}),
        }}
        >
          <div className="position-relative" style={{minHeight: "calc(100svh - 98px)", top: "-16px"}}>
          {pageData && pageData.length > 0 && pageData.map((pageItem, pageIndex) => {
            if(pageItem.type === "Background"){
              return(
                <React.Fragment key={pageIndex}>
                  <Background key={pageItem.id} data={pageItem} />
                </React.Fragment>
              )
            }
          })}

          {pageData && pageData.length > 0 && pageData.map(({ type, values }, index) => {
            if(type === "Page"){
              return(
                <React.Fragment>
                  {values &&
                    values.length > 0 &&
                    values?.map((item, ind) => {
                      let headerDepth = item?.headerDepth ? item?.headerDepth : 0
                      let depthInPx = (600 * headerDepth)/100
                      return (
                      <>
                        <div className="page_control_header" 
                          style={{
                            backgroundColor: item?.headerColor, height: item?.headerDepth ? `${depthInPx}px` : "0px",
                            }}
                          >
                        </div>
                        {item?.headerIcon &&
                          <div style={{...getPaddingStyle(item), ...getImageStyles(item?.align, false, item?.iconHeight, item?.iconHeight, "", item, type, false), zIndex: 999}}>
                            <img  alt="logo"
                              src={item?.headerIcon} 
                              style={{width: item?.iconWidth ? `${item?.iconWidth}px` : "50px", height: item?.iconHeight ? `${item?.iconHeight}px` : "20px"}} />
                          </div>
                        }
                        
                      </>
                    )}
                    )}
                </React.Fragment>
              )
            }
          })}
            <div style={{ height:"100%", marginLeft:5, marginRight:5, paddingBottom: navData?.direction ? "65px" : "0", position: "relative", zIndex: 2 }}>
              {pageData.map((dataItem) => renderComponent(dataItem))}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Container;
