export default function templateReducer(state = [], action) {
  switch (action.type) {
    case "GET_TEMPLATE_META_DATA":
      state = { ...state, data: action.payload };
      return state;
    case "SET_TEMPLATE_META_DATA":
      state = { ...state, tmData: action.payload };
      return state;
    case "GET_USER_CAMPAIGN_DATA":
      state = { ...state, getCampData: action.payload };
      return state;
    case "SET_USER_CAMPAIGN_DATA":
      state = { ...state, getUserCampData: action.payload };
      return state;

    default:
      return state;
  }
}
