import { takeLatest, put, call, all } from "redux-saga/effects";
import { getAppointment, getBookAppointment, setAppointment, setBookAppointment,getUserPharmacyAppot,
   setBookPharmacyAppointment,setUserPharmacyAppointment, setSummaryInfo, getScheduler, 
   setScheduler} from "./getAppointment";
import axios from "axios";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";
import { decodeToken } from '../../../utils';

const token = localStorage.getItem('token')
export function* getAllAppointment(data) {
  const config = {
    headers: {
      "Authorization": `Bearer ${data.payload.token}`,
    },
  };
  try {
    yield put(setLoader(true))
    const appointments = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetAllAppointment?schedulerId=${data.payload.scheduleCode}`, config);
 
    if (appointments && appointments.data && appointments.data.length <= 0) {
     // yield put(setTokenError("It looks like all the current appointment slots are filled. Please call 425-260-6684 to allow us to find you a time and get you set up"));
      return;
    }
    yield put(setAppointment(appointments.data));
    yield put(setLoader(false))
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 404) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    yield put(setAppointment('error'));
  }
}

export function* bookAppointment(data) {
  let bearerToken = localStorage.getItem('token');
  const config = {
    headers: {
      "Authorization": `Bearer ${bearerToken}`,
    },
  };
  const id = data.payload.id
  try {
    yield put(setLoader(true))
    yield axios.post(`${process.env.REACT_APP_BASE_URL}/api/appointment`, data.payload, config);
    yield put(getBookAppointment({ token: bearerToken }))
    yield put(setLoader(false))
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* bookAndUpdateAppointment(data) {
  let bearerToken = localStorage.getItem('token');
  const config = {
    headers: {
      "Authorization": `Bearer ${bearerToken}`,
    },
  };
  const id = data.payload.id
  try {
    yield axios.post(`${process.env.REACT_APP_BASE_URL}/api/appointment/update`, data.payload, config);
    yield put(getBookAppointment({ token: bearerToken }))
    yield put(setLoader(false))
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* bookPharmacyAppointment(data) {
 
  let bearerToken = localStorage.getItem('token');
  const config = {
    headers: {
      "Authorization": `Bearer ${bearerToken}`,
    },
  };
  try {
    yield put(setLoader(true));
    yield axios.post(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/BookAppointment`, data.payload, config);
    yield put(getUserPharmacyAppot({ token: bearerToken }))
    yield put(setLoader(false))
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* getBookedAppointment(data) {
  const config = {
    headers: {
      "Authorization": `Bearer ${data.payload.token}`,
    },
  };
  try {
    yield put(setLoader(true))
    const booked = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetBookedAppointments?schedulerId=${data.payload.scheduleCode}`, config);
 
    if (booked && booked.data) {
      yield put(setBookAppointment(booked.data))
      yield put(setLoader(false))
    }
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 404) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}


export function* getPharmacyBookedAppointment(data) {
  const config = {
    headers: {
      "Authorization": `Bearer ${data.payload.token}`,
    },
  };
  try {
    yield put(setLoader(true))
    const bookedPharmacyBooked = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetBookedAppointments?schedulerId=${data.payload.scheduleCode}`, config);
    yield put(setLoader(false))
    if (bookedPharmacyBooked && bookedPharmacyBooked.data) {
      yield put(setBookPharmacyAppointment(bookedPharmacyBooked.data))
    }
  } catch (error) {
    console.log('error', error);
    if (error.response.status === 401 || error.response.status === 404) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}

export function* getUserPharmacyBookAppointment(data) {
  let bearerToken = localStorage.getItem('token');
  let user = decodeToken(bearerToken);
  const config = {
    headers: {
      "Authorization": `Bearer ${bearerToken}`,
    },
  };
  try {
    yield put(setLoader(true))
    const userPharmacyBooked = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetUserBookedAppointment?scheduleCode=${user.scheduleCode}&patientGuid=${user.UserId}`, config);
  
    if (userPharmacyBooked  && userPharmacyBooked.data) {
      yield put(setUserPharmacyAppointment(userPharmacyBooked.data));
    }
    yield put(setLoader(false))
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}


export function* getSchedulers() {
  let bearerToken = localStorage.getItem('token');
  let scheduleCode = localStorage.getItem("scheduleCode");
  const config = {
    headers: {
      "Authorization": `Bearer ${bearerToken}`,
    },
  };
  try {
    yield put(setLoader(true))
    const scheduler = yield axios.get(`${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/GetScheduler?schedulerId=${scheduleCode}`, config);
    yield put(setLoader(false))
    if (scheduler && scheduler.data) {
      yield put(setScheduler(scheduler.data))
    }
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 404) {
      yield put(setTokenError("This link is no longer valid. Please call 425-260-6684 to arrange your appointment"))
    } else {
      yield put(setError("An error occurred while processing your request, please contact admin"));
    }
    yield put(setLoader(false))
    console.log("error", error);
  }
}



export function* onGetAllAppointment() {
  yield takeLatest("GET_APPOINTMENT", getAllAppointment);
}

export function* onGetPharmacyAppointment()
{
  yield takeLatest("GET_PHARMACY_APPOINTMENTS", getPharmacyBookedAppointment);
} 

export function* onBookAppointment() {
  yield takeLatest("BOOK_APPOINTMENT", bookAppointment);
}

export function* onBookPharmacyAppointment() {
  yield takeLatest("BOOK_PHARMACY_APPOINTMENT", bookPharmacyAppointment);
}

export function* onBookAndUpdateAppointment() {
  yield takeLatest("BOOK_UPDATE_APPOINTMENT", bookAndUpdateAppointment);
}

export function* onBookedAppointment() {
  yield takeLatest("GET_BOOK_APPOINTMENT", getBookedAppointment);
}

export function* onUserPharmacyBookedAppointment() {
  yield takeLatest("GET_USER_PHARMACY_APPOINTMENT", getUserPharmacyBookAppointment);
}

export function* onGetScheduler() {
  yield takeLatest("GET_SCHEDULER", getSchedulers);
}

export function* modelSagas() {
  yield all([call(onGetAllAppointment), call(onBookAppointment), call(onBookedAppointment),
     call(onBookPharmacyAppointment), call(onBookAndUpdateAppointment), 
     call(onGetPharmacyAppointment), call(onUserPharmacyBookedAppointment),
    call(onGetScheduler)]);
}
