import React from "react";

const IconAction = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => (
          <div key={ind}
            className="mb-3"
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "0.7rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={item?.iconImage}
                style={{ height: "auto", width: "2.5rem" }}
              />
            </div>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: item?.bodyText,
                }}
                style={{
                  color: item?.textColor,
                  fontSize: "1rem",
                  lineHeight: "20px",
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default IconAction;
