import React from 'react';
import loader from "../../assets/images/Spin-Preloader-1.gif";
import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Loader = () => {
    return (
        <div className="loader-container">
            <Modal
                show={true}
                backdrop={false}
                keyboard={false}
                centered
            >
                <Modal.Body className="text-center">
                    <p className="text-blue">Please wait, we are processing your request.</p>
                    <img src={loader} alt="Spin-Preloader-1.gif" className="loading-img" />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Loader;
