import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectMtmResponse, selectPageLocalizedData, selectMedSurveyOptions } from '../redux/store/mtm/mtmSelector';

const MtmSummary = () => {
    
    const mtmResponse = useSelector(selectMtmResponse);
    const localizedData = useSelector(selectPageLocalizedData);
    const medSurveyOptions = useSelector(selectMedSurveyOptions);
    const [summaryMsg, setSummaryMsg] = useState();

    const getLocalizedDataByKey = (key) => {
        if(localizedData && localizedData.localizedData){
            return localizedData.localizedData.filter(x => x.Key == key)[0].Value;
        }
        return "";
    }

    useEffect(() => {
        let providerStopOptionSelected = true;
        if (mtmResponse && mtmResponse.resp && mtmResponse.resp.length > 0 && medSurveyOptions && Object.keys(medSurveyOptions).length > 0) {
            if(mtmResponse.resp.filter(x => x.Status_de == "bad" || x.Status_de == "notsure").length > 0){
                let respDetails = mtmResponse.resp.filter(x => x.Status_de == "bad" || x.Status_de == "notsure")[0];
                medSurveyOptions.medSurveyOptions[respDetails.Status_de].SubOptions.map(x => {
                    let respOptionDetails = JSON.parse(respDetails.Response_de);
                    if(Object.keys(respOptionDetails).filter(k => k == x.BaseSubOptionDe && x.SubOptionType == null).length > 0){
                        providerStopOptionSelected = false;
                    }
                });
                if(providerStopOptionSelected){
                    setSummaryMsg(getLocalizedDataByKey("SURVEY_SUMMARY_GOOD_MSG"));    
                }
                else{
                    setSummaryMsg(getLocalizedDataByKey("SURVEY_SUMMARY_MSG"));
                }
            }
            else{
                setSummaryMsg(getLocalizedDataByKey("SURVEY_SUMMARY_GOOD_MSG"));
            }
        }
    }, [mtmResponse, localizedData, medSurveyOptions]);

    
    return(
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
            <div className='card-header bg-blue text-white text-center'>
          <img class=" w-15" src="../../Logo.svg" />
        </div>
                <div className='card-body card-body-h-vh bg-card3 p-0 container-340 '>
                    <div className="mt-auto mb-5">
                        <div className='bg-white text-blue card-help-body mb-3 cancel_enrollment opicity-5'>
                            <p class="mb-0 text-center normal_text">{summaryMsg}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MtmSummary;