export const addMtmResp = (data) => {
  return {
    type: 'ADD_MTM_RESP',
    payload: data,
  };
};

export const getMtmResponseAction = (data) => {
  return {
    type: 'GET_MTM_RESP',
    payload: data,
  };
};

export const setMtmResponse = (data) => {
  return {
      type: 'SET_MTM_RESP',
      payload: data,
  };
};

export const getMtmSurveyOptionAction = (data) => {
  return {
    type: 'GET_MTM_SURVEY_OPTIONS',
    payload: data,
  };
};

export const setMtmSurveyOptions = (data) => {
  return {
      type: 'SET_MTM_SURVEY_OPTIONS',
      payload: data,
  };
};

export const getPageLocalizedDataAction = (data) => {
  return {
    type: 'GET_PAGE_LOCALIZED_DATA',
    payload: data,
  };
};

export const setPageLocalizedData = (data) => {
  return {
      type: 'SET_PAGE_LOCALIZED_DATA',
      payload: data,
  };
};



