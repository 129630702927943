import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import {
  getFitResponseAction,
  addFitResp,
} from "../../redux/store/fit/fitAction";
import { getMammoFacLocationAction } from "../../redux/store/mam/mammoAction";
import { setError, setLoader } from "../../redux/store/loader/loaderAction";
import { decodeToken } from "../../utils";
import { selectFitResponse } from "../../redux/store/fit/fitSelector";
import { selectMammoLocation } from "../../redux/store/mam/mammoSelector";
import { error } from "../../redux/store/loader/loaderSelector";
import moment from "moment"; // Import moment
import { ClipLoader } from "react-spinners";
import { Underline } from "react-feather";
const MamoLocation = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const [campaignType, setCampaignType] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fitResponse = useSelector(selectFitResponse);
  const mammoLocations = useSelector(selectMammoLocation);
  const errorMessage = useSelector(error);
  const { company, campaignId } = useParams();

  const [loading, setLoading] = useState(true);
  const mapStyles = {
    height: "300px",
    width: "100%",
  };

  const mapContainerStyles = {
    height: "300px",
    width: "100%",
    border: "4px solid #DDDDDD",
    borderRadius: "15px",
  };

  useEffect(() => {
    dispatch(setError(""));
    const token = localStorage.getItem("token");
    const data = decodeToken(token);
    setCampaignType(data?.MessageType);
    if (!token) {
      dispatch(setError("Token is missing. Please contact Admin"));
      dispatch(setLoader(false));
    } else {
      dispatch(
        getFitResponseAction({ id: data?.uid, campCode: data?.campCode })
      );
      if (!mammoLocations) {
        dispatch(getMammoFacLocationAction({ type: data.MessageType }));
      }
    }

    let location = mammoLocations?.find((x) => x.facilityAbbr === "RAPTOH");
    if (location === undefined || location === null)
      location = mammoLocations?.find((x) => x.facilityAbbr === "BVU");

    let existinglocation = mammoLocations?.find(
      (x) => x.primaryId === fitResponse?.locationId
    );
    if (existinglocation !== undefined && existinglocation !== null)
      location = existinglocation;

    setSelectedLocation(location);
  }, [mammoLocations]);

  useEffect(() => {
    if (fitResponse) {
      dispatch(setLoader(false));
      if (fitResponse.responseDe === "Y") {
        if (
          selectedLocation?.phoneNumber !== null &&
          selectedLocation?.phoneNumber !== undefined &&
          selectedLocation?.phoneNumber !== ""
        ) {
          window.location.href = `tel:+1${selectedLocation?.phoneNumber}`;
        } else {
          window.location.href = `tel:${+1877751}`;
        }
      } else if (fitResponse.responseDe !== "N") {
        handleSendMammo("N");
      }
    }
  }, [fitResponse, navigate, company, campaignId, dispatch]);

  const handleCallClick = () => {
    window.location.href = `tel:+1${"877-751-4324"}`;
  };

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const handleClickOnDisplayAddress = (location, index) => {
    setSelectedLocation(location);
    setShowInfo({ index: index, info: location?.faC_ADDRESS_FULL });
  };

  const getDate = () => {
    let s = new Date().getSeconds();
    let d = randomNumberInRange(1, 1000);
    return `${d}${s}`;
  };

  const handleSendMammo = (resDe) => {
    dispatch(setLoader(true));
    const token = localStorage.getItem("token");
    const user = decodeToken(token);

    const data = {
      userGuid: user.uid,
      campaignCode: user.scheduleCode,
      responseDe: resDe,
      locationId: selectedLocation?.primaryId,
    };
    dispatch(addFitResp(data));
  };
  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom card-help">
        <div className="card-header bg-inky text-white text-center">
          <img className="w-15" src="../../../Logo.svg" alt="Logo" />
        </div>
        <div className="card-body card-body-h-vh bg-white p-0 container-340">
          <div className="mb-2">
            <div style={mapStyles} className="mb-2 px-3">
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    zIndex: 1,
                    height: "100%",
                  }}
                >
                  <ClipLoader
                    size={50}
                    height="50px"
                    width="50px"
                    color={"rgba(13, 28, 61, 0.9)"}
                    loading={loading}
                  />
                </div>
              )}
              {mammoLocations && (
                <GoogleMap
                  onLoad={handleLoad}
                  mapContainerStyle={mapContainerStyles}
                  zoom={8}
                  center={{
                    lat: mammoLocations[0]?.latitude || 47.298557, // Default to a fallback coordinate if mammoLocations is empty
                    lng: mammoLocations[0]?.longitude || -122.3321468,
                  }}
                >
                  {mammoLocations.map((location, index) => {
                    let mapKey = getDate(index);
                    return (
                      <Marker
                        key={mapKey}
                        position={{
                          lat: location.latitude,
                          lng: location.longitude,
                        }}
                        onClick={() =>
                          handleClickOnDisplayAddress(location, mapKey)
                        }
                        title={location?.faC_ADDRESS_FULL}
                      ></Marker>
                    );
                  })}
                </GoogleMap>
              )}
            </div>
            {selectedLocation && (
              <div className="d-flex gap-3 justify-content-between px-3">
                <div className="w-50">
                  <ul className="list-unstyled ms-0 fw-bold">
                    <li className="address_info_title">
                      {" "}
                      {selectedLocation?.external === "YES"
                        ? "Partner location"
                        : "KP location"}
                    </li>
                    <li className="address_info_desc">
                      {selectedLocation?.facilityName}
                    </li>
                    <li className="address_info_desc">
                      <span className="d-block w-100">
                        {selectedLocation?.street}
                      </span>
                      <span className="text-nowrap d-block w-100">
                        {" "}
                        {selectedLocation?.state} {selectedLocation?.zipcode}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="w-50">
                  <ul className="list-unstyled ms-0 fw-bold">
                    <li className="address_info_title">Hours</li>
                    <li className="address_info_desc">
                      {selectedLocation?.weekDayOpen}-
                      {selectedLocation?.weekDayClose}
                      <br /> <span>Mon - Fri</span>
                    </li>
                    {selectedLocation?.saturdayOpen !== null &&
                      selectedLocation?.saturdayOpen !== "NULL" &&
                      selectedLocation?.saturdayOpen !== "" &&
                      selectedLocation?.saturdayOpen !== undefined &&
                      selectedLocation?.saturdayClose !== null &&
                      selectedLocation?.saturdayClose !== "NULL" &&
                      selectedLocation?.saturdayClose !== "" &&
                      selectedLocation?.saturdayClose !== undefined && (
                        <li className="address_info_desc">
                          {selectedLocation?.saturdayOpen}-
                          {selectedLocation?.saturdayClose}
                          <br />
                          <span>Sat - Sun</span>
                        </li>
                      )}
                    <li
                      className="address_info_desc"
                      style={{ textDecoration: "underline" }}
                    >
                      {selectedLocation?.phoneNumber}
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {selectedLocation?.facilityAbbr === "TAC" &&
              selectedLocation?.facilityType === "MAM" && (
                <div className="mt-auto mb-4">
                  <div className="card-thanks text-white text-center exended-card-help-body mb-1 p-1">
                      <span className="normal_text">
                      Extended weekend hours at this facility
                      </span>
                  </div>
                </div>
              )}
            <div className="mt-auto mb-2">
              <div className="card-thanks text-white card-help-body mb-1 p-1">
                <ol>
                  <li className="mb-1 normal_text">
                    Select a location from the map above.
                  </li>
                  {(campaignType === "3" || campaignType === "6") && (
                    <li className="mb-1 normal_text">Call to schedule.</li>
                  )}

                  {(campaignType === "1" ||
                    campaignType === "2" ||
                    campaignType === "5") && (
                      <>
                        <li className="mb-1 normal_text">
                          Click the button below to schedule.
                        </li>
                        <li className="mb-1 normal_text">
                          If you select a Rayus location, Rayus is partnering with
                          KP and will coordinate getting results to your Primary
                          Care team.
                        </li>
                      </>
                    )}
                </ol>
              </div>
            </div>
            <div className="d-flex flex-column">
              <span
                className="mb-0 text-center normal_text more_normal mt-2"
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Call to Schedule
              </span>
              <div
                className="btn_bordered mb-3"
                style={{
                  backgroundColor:
                    fitResponse?.responseDe === "Y" ? "#CCCCCC" : "",
                }}
              >
                <span
                  className="mb-0 text-center normal_text more_normal"
                  onClick={() => {
                    if (fitResponse.responseDe !== "Y") {
                      handleSendMammo("Y");
                    }
                  }}
                  style={{
                    cursor:
                      fitResponse?.responseDe === "Y"
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {" "}
                  {selectedLocation?.phoneNumber}
                </span>
              </div>
              <br />
              <p className="mb-1 normal_text px-2 text-center">
                If you have any questions, please call <br /> Kaiser Permanente
                at:
                <span
                  style={{ textDecoration: "underline" }}
                  onClick={() => handleCallClick()}
                >
                  {" "}
                  877-751-4324
                </span>
              </p>
            </div>
            <div className="d-flex flex-column gap-3 mt-5">
              <div className="d-flex justify-content-between mr-2 navigation_img_wrapper">
                <Link to={`/${company}/${campaignId}/mammo/summary`}>
                  <img
                    className="me-4 navigation_img"
                    src="/Back_dark.svg"
                    height="40"
                    width="100"
                    alt="Back Dark"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MamoLocation;
