import React from 'react'

const ActionHeader = ({ action }) => {
  return (
    <>
      <div className='action-tile text-inky-blue fw-bold'>{action.title}</div>
      <div className='action-header'>

        <div>
          <img class=" w-20" src="../../../../ActionCheck.svg" />
        </div>
        <div className='action-title text-inky-blue fw-bold'>{action?.Value}</div>
      </div>
      <div class="d-flex mt-2">
        {action?.Img && action?.ImgTime && <div class="d-flex flex-column flex-wrap clock-image-container">
          <div class="clock-icon">
            <img src={"../../../../" + action?.Img} alt="" />
          </div>
          <p class="mb-0 text-inky-blue appointment-time">{action?.ImgTime}</p>
        </div>}
        <div className={'text-inky-blue ' + action?.TagLineCss}>
          <div className='fs-6 fw-semibold'> {action?.TagLine}</div>
        </div>
      </div>
    </>
  )
}

export default ActionHeader