export const saveCampaignOpenStatus = (data) => {
  return {
    type: 'SAVE_CAMPAIGN_OPEN_STATUS',
    payload: data,
  };
};
  
 
  
  
  