import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { bookPharmacyAppointment } from '../../redux/store/appointment/getAppointment';
import { setError, setLoader, setTokenError } from '../../redux/store/loader/loaderAction';
import { userBookedPharmacyAppointment } from '../../redux/store/appointment/appointmentSelector';
import { getUserPharmacyAppot } from '../../redux/store/appointment/getAppointment';
import { useDispatch, useSelector } from 'react-redux'
import { decodeToken } from '../../utils';

const OtpEnrollment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bookedAppointment = useSelector(userBookedPharmacyAppointment);
    const { company, campaignId } = useParams();
    useEffect(() => {
        dispatch(getUserPharmacyAppot({}));
        if (bookedAppointment) {
            if (bookedAppointment.stayEnrolled && bookedAppointment.stayEnrolled.includes('outreach')) {
                navigate(`/${company}/${campaignId}/stayEnrolled`);
            } else if (bookedAppointment.optOut && bookedAppointment.optOut.includes('Opt out')) {
                navigate(`/${company}/${campaignId}/canceledService`);
            }
        }
    }, [bookedAppointment])

    const handleOutService = () => {
        const optOut = 'Opt out of this service';
        handleStayEnrolOptOutService('', optOut);
        navigate(`/${company}/${campaignId}/canceledService`);
    }
    const handleStayEnrolled = () => {
        const stayEnrolled = 'Stay enrolled for outreach later';
        handleStayEnrolOptOutService(stayEnrolled, '');
        navigate(`/${company}/${campaignId}/stayEnrolled`);
    }
    const handleStayEnrolOptOutService = (stayEnrolled, optOut) => {
        dispatch(setLoader(true))
        let token = localStorage.getItem("token");
        const user = decodeToken(token);
        const data = {
            id: '',
            Is_appt_booked: false,
            optOut: optOut,
            stayEnrolled: stayEnrolled,
            patientGuid: user.UserId,
            scheduleCode: user.scheduleCode
        }
        dispatch(bookPharmacyAppointment(data));
    }

    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inky text-white text-center'>
                    <img className=" w-15" src="../../Logo.svg" />
                </div>
                <div className='card-body card-body-h-vh bg-card2 p-0 container-340 '>
                    <div className="mt-auto mb-5">
                        <div className='bg-white text-inky card-help-body mb-3 mt-3 opicity-5'>
                            <p className="mb-0 normal_text text-center">OK. Many of our patients enjoy
                                        the chance to talk with our
                                        clinical pharmacist to ask
                                        questions about prescription or
                                        OTC medicines, check for
                                        drug interactions, or review for lower
                                        cost options. Remember this
                                        service is at no cost. 
                                        <br/> 
                                        <p className="mt-2 text-center">What would you like to do:</p></p>
                        </div>
                        <div className='d-flex flex-column gap-3'>
                            <div className='card-thanks text-white card-help-body' onClick={() => handleOutService()}>
                                <p className="mb-0 text-center normal_text">Opt-out of this service</p>
                            </div>
                            <div className='card-thanks text-white card-help-body' onClick={() => handleStayEnrolled()}>
                                <p className="mb-0 text-center normal_text">Stay enrolled for <br /> outreach later in the year</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OtpEnrollment;