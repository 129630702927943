import { all, call } from 'redux-saga/effects';
import { modelSagas } from './appointment/getAllAppointment';
import { verifyTokenSagas } from './verifyToken/getVerifyToken';
import { mtmSagas } from './mtm/mtmSagas';
import { fitSagas } from './fit/fitSagas';
import { comSagas } from './common/comSagas';
import { mammoSagas} from './mam/mammoSagas';
import { templateSagas} from './template/templateSagas';

export function* rootSaga() {
  yield all([call(modelSagas), call(verifyTokenSagas), call(mtmSagas), call(fitSagas), call(mammoSagas), call(templateSagas), call(comSagas)]);
}
