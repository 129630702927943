export const setAppointment = (data) => {
  return {
    type: 'SET_APPOINTMENT',
    payload: data,
  };
};

export const getAppointment = (data) => {
  return {
    type: 'GET_APPOINTMENT',
    payload: data
  };
};

export const getUserPharmacyAppot = (data) => {
  return {
    type: 'GET_USER_PHARMACY_APPOINTMENT',
    payload: data
  };
};

export const setUserPharmacyAppointment = (data) => {
  return {
    type: 'SET_USER_PHARMACY_APPOINTMENT',
    payload: data
  };
};
export const bookAppointment = (data) => {
  return {
    type: 'BOOK_APPOINTMENT',
    payload: data,
  };
};

export const bookPharmacyAppointment = (data) => {
  return {
    type: 'BOOK_PHARMACY_APPOINTMENT',
    payload: data,
  };
};

export const setBookPharmacyAppointment = (data) => {
  return {
    type: 'SET_BOOKED_PHARMACY_APPOINTMENTS',
    payload: data,
  };
};

export const getPharmacyBookedAppointment = (data) => {
  return {
    type: 'GET_PHARMACY_APPOINTMENTS',
    payload: data
  };
};

export const getScheduler = () => {
  return {
    type: 'GET_SCHEDULER',
  };
};

export const setScheduler = (data) => {
  return {
    type: 'SET_SCHEDULER',
    payload: data
  };
};

export const bookAndUpdateAppointment = (data) => {
  return {
    type: 'BOOK_UPDATE_APPOINTMENT',
    payload: data,
  };
};

export const getBookAppointment = (data) => {
  return {
    type: 'GET_BOOK_APPOINTMENT',
    payload: data
  };
};

export const setBookAppointment = (data) => {
  return {
    type: 'SET_BOOK_APPOINTMENT',
    payload: data,
  };
};

export const setSummaryInfo = (data) => {
  return {
    type: 'SET_SUMMARY_INFO',
    payload: data,
  };
};




