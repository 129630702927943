import React, { useEffect, useState } from "react";
import "./../index.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppointment,
  selectBookedAppointment,
  allBookedAppointment,
  userBookedPharmacyAppointment,
  scheduler,
} from "../../redux/store/appointment/appointmentSelector";
import {
  bookPharmacyAppointment,
  getAppointment,
  getPharmacyBookedAppointment,
  getUserPharmacyAppot,
  getScheduler
} from "../../redux/store/appointment/getAppointment";
import {
  setError,
  setLoader,
  setTokenError,
} from "../../redux/store/loader/loaderAction";
import ReactDatePicker from "react-datepicker";
import { retry } from "redux-saga/effects";
import { decodeToken } from '../../utils';
const ApptCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [apptSelected, setApptSelected] = useState(false);
  const [apptSelectedRow, setApptSelectedRow] = useState("");
  const [apptPayLoad, setApptPayLoad] = useState({});
  const appointments = useSelector(selectAppointment);
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [slot, setSlot] = useState();
  const [day, setDay] = useState();
  const [minDate, setMinDate] = useState("");
  const bookedAppointment = useSelector(selectBookedAppointment);
  const allBookedAppointments = useSelector(allBookedAppointment);
  const userBookedPharmacyAppt = useSelector(userBookedPharmacyAppointment);
  const schedulers = useSelector(scheduler);
  const { company, campaignId } = useParams();
  const timeSlotSelected = (payload) => {
    setApptSelected(true);
    setApptSelectedRow(payload.primaryId);
    setApptPayLoad(payload);
  };

  const handleBookedAppointment = () => {
    dispatch(setLoader(true));
    if (userBookedPharmacyAppt) {
      dispatch(setLoader(false));
      dispatch(
        setTokenError(
          `Your appointment is already booked on ${moment(
            bookedAppointment.date
          ).format("ddd Do MMMM YYYY")}`
        )
      );
      return;
    }
    let token = localStorage.getItem('token');
    const user = decodeToken(token);
    const data = {
      primaryId: apptPayLoad.primaryId,
      patientGuid: user.UserId,
      scheduleCode: user.scheduleCode,
      is_appt_booked: true,
    };
    dispatch(bookPharmacyAppointment(data));
  };

  useEffect(() => {
    dispatch(getUserPharmacyAppot({}));
    if (userBookedPharmacyAppt && userBookedPharmacyAppt.is_appt_booked) {
      navigate(`/${company}/${campaignId}/appointment/Summary`);
    }
  }, [userBookedPharmacyAppt]);

  useEffect(() => {
    if (appointments && appointments != "error" && appointments.length > 0) {
      const organizedTimestamps = {};
      for (const appointment of appointments) {
        const bookedAppt = allBookedAppointments?.find(
          (x) => x.appointmentId === appointment.primaryId
        );
        if (bookedAppt) {
          appointment.is_appt_booked = bookedAppt.is_appt_booked;
        } else {
          appointment.is_appt_booked = false;
        }
        const date = appointment.date.split("T")[0];
        if (!organizedTimestamps[date]) {
          organizedTimestamps[date] = [];
        }
        organizedTimestamps[date].push(appointment);
      }
      const dates = Object.keys(organizedTimestamps);
      const selectedDate = new Date(
        dates[0].toString().replace("-", "/").replace(/-/g, "/")
      );
      setSlot(organizedTimestamps);
      var date = dates[dates.length - 1];
      let endDate = new Date(
        date.toString().replace("-", "/").replace(/-/g, "/")
      );

      var bookingDelay = schedulers?.bookingDelay ?? 0;
      const currDate = new Date();
      currDate.setDate(currDate.getDate() + bookingDelay);

      let nextApptDate = new Date(currDate);
      let nextApointmentDay = nextApptDate.getDay(); // Adding 1 here
      
      // Keep iterating until a valid appointment day is found
      while (!schedulers?.weekDayList.includes(nextApointmentDay)) {
        nextApptDate.setDate(nextApptDate.getDate() + 1);
        nextApointmentDay =  nextApptDate.getDay(); // Adding 1 here as well
      }
      setDate(nextApptDate);   // Set the selected date
      setMinDate(currDate);  
      setEndDate(endDate);
    }
  }, [appointments]);

  useEffect(() => {
    if (slot && date) {
      setDay(slot[moment(date).format("YYYY-MM-DD")]);
    }
  }, [date]);

  useEffect(() => {
    if (
      appointments === undefined ||
      appointments === null ||
      appointments.length <= 0
    ) {
      let token = localStorage.getItem("token");
      const user = decodeToken(token);
      let scheduleCode = localStorage.getItem("scheduleCode");
      dispatch(getPharmacyBookedAppointment({ token: token, userId: user.UserId, scheduleCode: scheduleCode }));
      dispatch(getScheduler({scheduleCode: scheduleCode}));
      dispatch(getAppointment({ token: token, scheduleCode: scheduleCode }));
    }
  },[appointments]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setDate(start);
    setShow(false);
    setApptSelected(false);
    setApptSelectedRow("");
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    // Return true if the day is not Saturday (6) or Sunday (0)
    //return day !== 0 && day !== 6;
    return schedulers?.weekDayList.includes(day);
  };

  return (
    <div className="container-fluid g-0">
      <div className="card card-custom">
        <div className="card-header bg-inky text-white text-center">
          <img class=" w-15" src="../../Logo.svg" />
        </div>
        <div className="card-body card-body text-inky container-340 ">
          <h5 className="text-center normal_text">
            Pick a timeslot for us to call you
          </h5>
          <div className="mb-2 cander_red">
            <div className="mb-2 calender-picker">
              <div className="calendar normal_text">
                <ReactDatePicker
                  selected={date}
                  formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  onChange={onChange}
                  maxDate={endDate}
                  selectsRange
                  inline
                  minDate={minDate}
                  filterDate={isWeekday}
                />
              </div>
            </div>
          </div>
          <div className="time-zone-container">
            <div
              className="d-flex justify-content-between flex-wrap"
              style={{}}
            >
              {day &&
                day.length > 0 &&
                day !== "error" &&
                day.map((data) => {
                  //const time = moment(data?.date).utc().format("h:mm A");
                  const time = moment(data?.date).format("h:mm A");
                  return (
                    <div
                      className="time-zone-radio-group"
                      onClick={() =>
                        data.is_appt_booked != 1 && timeSlotSelected(data)
                      }
                    >
                      <input
                        type="radio"
                        style={{
                          backgroundColor: data.is_appt_booked ? "grey" : apptSelectedRow === data.primaryId ? "#F0F7FA" : " ",
                        }}
                        className={data.is_appt_booked? "time-zone-radio not-allowed" : "time-zone-radio "}
                        value={time}
                        name="timeZone"
                        id={data["id"]}
                        disabled={userBookedPharmacyAppt ? true : false}
                      />
                      <label
                        className={data.is_appt_booked ? "time-zone-label normal_text1 not-allowed" : "time-zone-label normal_text1"}
                        style={{ color: apptSelectedRow === data.primaryId ? "" : "", 
                        borderColor: apptSelectedRow === data.primaryId ? "#003b71" : "",
                        borderWidth: apptSelectedRow === data.primaryId ? 2 : "",
                       }}
                        for={data["id"]}
                      >
                        {time}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
          {
            <>
              <div className="mt-4 text-center m-auto">
                <button
                  className={
                    "btn btn-outline-primary curved-button normal_text p-0 w-50 w-lg-50" +
                    (apptSelected ? " " : " disabled")
                  }
                  style={{ backgroundColor:apptSelected?"#F0F7FA":"",
                  borderColor: apptSelected ? "#003b71" : "",
                  borderWidth: apptSelected? 2 : "",
                  fontWeight: apptSelected? "bold" : "",}}
                  disabled={!apptSelected}
                  onClick={() => apptSelected && handleBookedAppointment()}
                >
                  Book Appointment
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default ApptCard;
