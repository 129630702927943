import { takeLatest, put, call, all } from "redux-saga/effects";
import axios from "axios";
import { setError, setLoader, setTokenError } from "../loader/loaderAction";

export function* saveCampaignOpenStatus(data) {
  const token = localStorage.getItem("token");
  if (data === undefined) return;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
       'Content-Type': 'application/json'
    },
   
  };
  try {
    yield axios.post(
      `${process.env.REACT_APP_ELXO_BASE_URL}/api/ScheduleAppointment/SaveCampaignOpenStatus`,
      data.payload,
      config
    );
    yield put(setLoader(false));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(setTokenError("This link is no longer valid."));
    } else {
      yield put(setError("An error occurred please contact admin"));
    }
    yield put(setLoader(false));
  }
}

export function* onSaveCampaignOpenStatus() {
  yield takeLatest("SAVE_CAMPAIGN_OPEN_STATUS", saveCampaignOpenStatus);
}

export function* comSagas() {
  yield all([call(onSaveCampaignOpenStatus)]);
}
