import React, { useEffect, useState } from 'react'
import './../index.css'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams, useParams } from 'react-router-dom'
import { bookPharmacyAppointment } from '../../redux/store/appointment/getAppointment';
import { selectBookedAppointment, allBookedAppointment } from '../../redux/store/appointment/appointmentSelector'
import { getVetifyApi, getVetifyApiTokenApi } from '../../redux/store/verifyToken/getVerifyTokenData';
import { setError, setLoader, setTokenError } from '../../redux/store/loader/loaderAction';
import { error } from '../../redux/store/loader/loaderSelector';
import moment from 'moment'
import { decodeToken } from '../../utils';
const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const errorMessage = useSelector(error)
    const queryParams = new URLSearchParams(location.search);
    const { company, campaignId } = useParams();
    const allBookedAppointments = useSelector(allBookedAppointment);
    useEffect(() => {
        dispatch(setLoader(true))
        dispatch(setError(""))
        const token = queryParams.get('token');
        var user = decodeToken(token);

        if (!token) {
            dispatch(setError("token is missing. Please contact Admin"))
            dispatch(setLoader(false))
            return;
        } else {
            dispatch(getVetifyApiTokenApi({ token: token, scheduleCode: user.scheduleCode }))
        }
        if (allBookedAppointments) {
            const bookedAppt = allBookedAppointments.find(x => x.patientGuid == user.UserId);
            if (bookedAppt && bookedAppt.date) {
                navigate(`/${company}/${campaignId}/appointment/Summary`);
            }
        }
    }, [])

    useEffect(() => {
        const token = queryParams.get('token');
        var user = decodeToken(token);
        if (allBookedAppointments) {
            const bookedAppt = allBookedAppointments.find(x => x.patientGuid == user.UserId);
            if (bookedAppt) {
                if (bookedAppt.stayEnrolled && bookedAppt.stayEnrolled.includes('outreach')) {
                    navigate(`/${company}/${campaignId}/stayEnrolled`);
                } else if (bookedAppt.optOut && bookedAppt.optOut.includes('Opt out')) {
                    navigate(`/${company}/${campaignId}/canceledService`);
                } else if(bookedAppt.date) {
                    navigate(`/${company}/${campaignId}/appointment/Summary`);
                }else if (bookedAppt.phoneCall && bookedAppt.phoneCall.includes('Y')) {
                    navigate(`/${company}/${campaignId}/appointment/call`);
                }
            }
        }
    }, [allBookedAppointments])

    //old logic
    const handleScheduleAppointment = () => {
       navigate(`/${company}/${campaignId}/appointment`);
    };

    const handleMedicationReview = () => {
        navigate(`/${company}/${campaignId}/otpEnrollment`);
    };

    const handlePhoneCall = () => {
        const phoneCall = 'Y';
        handlePhoneCallService(phoneCall, '');
        navigate(`/${company}/${campaignId}/appointment/call`);
    }

    const handlePhoneCallService = (phoneCall) => {
        dispatch(setLoader(true))
        let token = localStorage.getItem("token");
        const user = decodeToken(token);
        const data = {
            id: '',
            Is_appt_booked: false,
            phoneCall: phoneCall,
            patientGuid: user.UserId,
            scheduleCode: user.scheduleCode
        }
        dispatch(bookPharmacyAppointment(data));
    }

    return (
        <div className='container-fluid g-0'>
            <div className='card card-custom card-help'>
                <div className='card-header bg-inky text-white text-center'>
                    <img className="w-15" src="../Logo.svg" />
                </div>
                <div className='card-body text-center p-2 '>
                    <p className="mb-0 normal_text">Select an action to get started</p>
                </div>

                {errorMessage == '' ? <div className='card-body card-body-h-vh bg-card1 p-0 container-340 '>
                    <div className='help-card-bottom help-card-1 d-flex flex-column gap-3'>
                        {/* <div className='bg-blue text-white card-help-body' onClick={handleScheduleAppointment}>
                            <p className="mb-0 normal_text">Schedule an appointment for a phone call</p>
                        </div> */}
                        <div className='card-thanks text-white card-help-body' onClick={handlePhoneCall}>
                            <p className="mb-0 normal_text">Schedule an appointment for a phone call</p>
                        </div>
                        <div className='card-thanks text-white card-help-body' onClick={handleMedicationReview}>
                            <p className="mb-0 normal_text">I don’t need a medication review this year</p>
                        </div>
                    </div>
                </div> : ""}
            </div>
        </div>
    )
}

export default Index