import React, { useEffect, useRef} from "react";
import './helpers/style.css'
import PlayIcon from '../../../assets/images/playIcon.png'
const Video = ({ data, handleSave }) => {
  const { values } = data;
  const campVideoRef = useRef(null);


  useEffect(() => {
    const handleModalClose = () => {
      campVideoRef.current.pause();
    }
    const modalElement = document.getElementById("staticBackdrop");
    if(modalElement){
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }
    return () => {
      if(modalElement){
        modalElement.removeEventListener('hidden.bs.modal', handleModalClose);
      }
    }
  },[]);

  const handlePlayPause = (item) => {
    const stringId = String(item?.id);
    if (campVideoRef.current) {
      if (campVideoRef.current.paused) {
        let playPromise = campVideoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            campVideoRef.current.play();
          })
          .catch(error => {
            console.log(error);
          });
        }
        // campVideoRef.current.play();
         handleSave({parentId: data?.id, id: stringId, question: "Watched video", result: "Y" })
      } else {
        campVideoRef.current.pause();
      }
    }
  }

 const handleAutoPlay=(item) =>{
  const stringId = String(item?.id);
    handleSave({parentId: data?.id, id: stringId, question: "Watched video", result: "Y" })
 }
  return (
    <div className="control_padding">
      {values &&
        values.length > 0 &&
        values?.map((item, ind) => {
          return (
            <React.Fragment>
              {values &&
                values.length > 0 &&
                values?.map((item, ind) => {
                  return (
                    <>
                      {item?.videoAlign === "yes" ?
                        <>
                          <div className="d-flex" style={{ border: `1px solid ${item?.borderColor}`, padding: "5px", borderRadius: "6px" }}>
                            <div style={{ width: "65%" }}>
                                <h4 className="mb-0" style={{textOverflow: "ellipsis", color: item?.titleColor, overflow: "hidden", textWrap: "nowrap", paddingRight: "1rem", 
                                fontSize: (item?.titleTextSize && item?.titleTextSize !== "px") 
                                ? item?.titleTextSize : "1.2rem"}}>{item?.videoTitle}</h4>
                              <p className="text_clamp mb-2 pe-2" dangerouslySetInnerHTML={{ __html: item?.videoDesc }} style={{ lineHeight: "1rem", marginTop: "0.5rem", marginBottom: "0.5rem" }}></p>
                              {item?.iconImage &&
                                <img alt="img"
                                  src={item?.iconImage}
                                  style={{height: "auto", width: item?.iconSize ? `${item?.iconSize}px` : "2rem" }}
                                />
                              }
                            </div>

                            <div style={{ width: "35%", height: "100px", border: "1px solid #000", borderRadius: "5px", position: "relative" }}>
                    
                              <video
                              
                                style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }}
                                className="video-element" controls>
                                <source src={item?.videoUrl} type="video/mp4" />
                              </video>
                              <img alt="img" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                onClick={() => handlePlayPause(item)}
                                src={PlayIcon} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "50px", height: "50px", zIndex: 999 }}
                              />
                            </div>
                          </div>

                          <div className="modal fade custom_fade_model" id="staticBackdrop" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-body p-0" style={{height: "50vh"}}>
                                  <video ref={campVideoRef}
                                    style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }}
                                    className="video-element" controls>
                                    <source src={item?.videoUrl} type="video/mp4" />
                                  </video>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div>
                            <div>
                              <h4 className="mb-0" style={{ fontSize: (item?.titleTextSize && item?.titleTextSize !== "px") ? item?.titleTextSize : "1.3rem", color: item?.titleColor, marginBottom: "7px" }}>{item?.videoTitle}</h4>
                              <p dangerouslySetInnerHTML={{ __html: item?.videoDesc }}></p>
                              {item?.iconImage &&
                                <img alt="img" src={item?.iconImage} style={{height: "auto", width: item?.iconSize ? `${item?.iconSize}px` : "2rem"}}  />
                              }
                            </div>
                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                              <iframe
                                title="video"
                                width={"100%"}
                                height={"200px"}
                                frameBorder="0"
                                src={item?.videoUrl}
                                onLoad={() => handleAutoPlay(item)}
                                key={ind}
                              ></iframe>
                            </div>
                          </div>
                        </>
                      }

                    </>
                  )
                }
                )}
            </React.Fragment>
          )
        }

        )}
    </div>
  );
};

export default Video;
