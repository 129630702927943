import React from "react";

const ColoHeader = ({ data }) => {
  const { values } = data;
  return (
    <div className="control_padding">
      <div>
        {values &&
          values.length > 0 &&
          values?.map((item, ind) => {
            return (
              <div key={ind} className="mb-3">
                <h1
                  style={{
                    color: item?.headerColor,
                    backgroundColor: "#fff",
                    borderRadius: "35px",
                    width: "100%",
                    padding: "5px 0",
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  {item?.header}
                </h1>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ColoHeader;
