import React from 'react'

const Cancel = ({ data }) => {
    return (
        <div className='cancel-main'>
            <div><svg class="cross-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg></div>
            <div>{data.Value}</div>
        </div>
    )
}

export default Cancel